<template>
  <div id="app">
    <index />
  </div>
</template>

<script>
import index from './view/index.vue'

export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style>
</style>
